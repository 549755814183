export const GET_USER_DETAILS = "cui/Dashboard/GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS =
    "cui/Dashboard/GET_USER_DETAILS_SUCCESS";
export const SET_CURRENT_USER_ID = "cui/Dashboard/SET_CURRENT_USER_ID";
export const UPDATE_QUESTION_FIELD = "cui/Dashboard/UPDATE_QUESTION_FIELD";
export const UPDATE_QUESTION_FIELD_STATUS =
    "cui/Dashboard/UPDATE_QUESTION_FIELD_STATUS";
export const CHANGE_NEW_TEST_NAME_STATUS =
    "cui/Dashboard/CHANGE_NEW_TEST_NAME_STATUS";
export const CREATE_NEW_TEST = "cui/Dashboard/CREATE_NEW_TEST";
export const CHANGE_CURRENT_MODE = "cui/Dashboard/CHANGE_CURRENT_MODE";
export const EDIT_MODES = "cui/Dashboard/EDIT_MODES";
export const EDIT_API_KEYS = "cui/Dashboard/EDIT_API_KEYS";
export const EDIT_ROLES = "cui/Dashboard/EDIT_ROLES";
export const EDIT_USERS = "cui/Dashboard/EDIT_USERS";
export const RESEND_TEAM_INVITE = "cui/Dashboard/RESEND_TEAM_INVITE";
export const DELETE_USER = "cui/Dashboard/DELETE_USER";
export const COMPLETELY_DELETE_USER = "cui/Dashboard/COMPLETELY_DELETE_USER";
export const DEACTIVATE_USER = "cui/Dashboard/DEACTIVATE_USER";
export const CHANGE_PERMISSION = "cui/Dashboard/CHANGE_PERMISSION";
export const UPLOAD_INTERVIEW_DATA = "cui/Dashboard/UPLOAD_INTERVIEW_DATA";
export const UPLOAD_INTERVIEW_DATA_RESULT =
    "cui/Dashboard/UPLOAD_INTERVIEW_DATA_RESULT";
export const UPLOAD_INTERVIEW_DATA_SUCCESS =
    "cui/Dashboard/UPLOAD_INTERVIEW_DATA_SUCCESS";
export const GET_INTERVIEWS_DATA = "cui/Dashboard/GET_INTERVIEWS_DATA";
export const GET_AUDIT_LOGS = "cui/Dashboard/GET_AUDIT_LOGS";
export const GET_AUDIT_LOGS_SUCCESS = "cui/Dashboard/GUI_AUDIT_LOGS_SUCCESS";
export const INTERVIEWS_DATA_SUCCESS = "cui/Dashboard/INTERVIEWS_DATA_SUCCESS";
export const ADD_COMPANY = "cui/Dashboard/ADD_COMPANY";
export const GET_QUESTIONS_DATA = "cui/Dashboard/GET_QUESTIONS_DATA";
export const GET_QUESTIONS_SUCCESS = "cui/Dashboard/GET_QUESTIONS_SUCCESS";
export const GET_ALL_CATEGORIES = "cui/Dashboard/GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS =
    "cui/Dashboard/GET_ALL_CATEGORIES_SUCCESS";
export const GET_QUESTIONS_DATA_TEST = "cui/Dashboard/GET_QUESTIONS_DATA_TEST";
export const GET_QUESTIONS_DATA_TEST_SUCCESS =
    "cui/Dashboard/GET_QUESTIONS_DATA_TEST_SUCCESS";
export const GET_INTERVIEW_QUESTIONS = "cui/Dashboard/GET_INTERVIEW_QUESTIONS";
export const GET_INTERVIEW_QUESTIONS_SUCCESS =
    "cui/Dashboard/GET_INTERVIEW_QUESTIONS_SUCCESS";
export const CREATE_INTERVIEW_QUESTION =
    "cui/Dashboard/CREATE_INTERVIEW_QUESTION";
export const ARCHIVE_INTERVIEW_QUESTION =
    "cui/Dashboard/ARCHIVE_INTERVIEW_QUESTION";
export const UPDATE_TEST_BUILDER_QUESTIONS =
    "cui/Dashboard/UPDATE_TEST_BUILDER_QUESTIONS";
export const UPDATE_TEST_METADATA = "cui/Dashboard/UPDATE_TEST_METADATA";
export const UPDATE_TEST_BUILDER_TEST =
    "cui/Dashboard/UPDATE_TEST_BUILDER_TEST";
export const GET_INTERVIEWS_AND_QUESTIONS_DATA =
    "cui/Dashboard/GET_INTERVIEWS_AND_QUESTIONS_DATA";
export const PUBLISH_UPDATE_TEST = "cui/Dashboard/PUBLISH_UPDATE_TEST";
export const RESET_TEST = "cui/Dashboard/RESET_TEST";
export const GET_SCORECARD_DATA = "cui/Dashboard/GET_SCORECARD_DATA";
export const GET_SCORECARD_DATA_SUCCESS =
    "cui/Dashboard/GET_SCORECARD_DATA_SUCCESS";
export const GET_SCORECARD_INTERVIEW_DATA =
    "cui/Dashboard/GET_SCORECARD_INTERVIEW_DATA";
export const GET_SCORECARD_INTERVIEW_DATA_SUCCESS =
    "cui/Dashboard/GET_SCORECARD_INTERVIEW_DATA_SUCCESS";

export const GET_SCORECARD_IP_DETAILS =
    "cui/Dashboard/GET_SCORECARD_IP_DETAILS";
export const GET_SCORECARD_IP_DETAILS_SUCCESS =
    "cui/Dashboard/GET_SCORECARD_IP_DETAILS_SUCCESS";
export const SET_CURRENT_SCORECARD_ID =
    "cui/Dashboard/SET_CURRENT_SCORECARD_ID";
export const UPDATE_CARD = "cui/Dashboard/UPDATE_CARD";
export const UPDATE_CARD_STATUS = "cui/Dashboard/UPDATE_CARD_STATUS";
export const SWITCH_PLANS = "cui/Dashboard/SWITCH_PLANS";
export const UPDATE_CREDITS_MANUALLY = "cui/Dashboard/UPDATE_CREDITS_MANUALLY";
export const UPDATE_CREDITS_MANUALLY_STATUS =
    "cui/Dashboard/UPDATE_CREDITS_MANUALLY_STATUS";
export const PRE_SET_ASSESSMENTS_SUCCESS =
    "cui/Dashboard/PRE_SET_ASSESSMENTS_SUCCESS";
export const SEND_DISCORD_MESSAGE = "cui/Dashboard/SEND_DISCORD_MESSAGE";
export const TRIGGER_KNOCK_NOTIFICATION =
    "cui/Dashboard/TRIGGER_KNOCK_NOTIFICATION";
export const SEND_REJECTION_EMAIL = "cui/Dashboard/SEND_REJECTION_EMAIL";
export const SEND_SHORTLIST_EMAIL = "cui/Dashboard/SEND_SHORTLIST_EMAIL";
export const SEND_BULK_RESULT_EMAIL_TO_CANDIDATE =
    "cui/Dashboard/SEND_BULK_RESULT_EMAIL_TO_CANDIDATE";
export const SEND_BULK_RESULT_EMAIL_TO_CANDIDATE_STATUS =
    "cui/Dashboard/SEND_BULK_RESULT_EMAIL_TO_CANDIDATE_STATUS";

export const RESEND_CANDIDATE_INVITE_EMAIL =
    "cui/Dashboard/RESEND_CANDIDATE_INVITE_EMAIL";
export const SEND_REMINDER_EMAIL = "cui/Dashboard/SEND_REMINDER_EMAIL";
export const DELETE_TEST = "cui/Dashboard/DELETE_TEST";
export const DELETE_TEST_STATUS = "cui/Dashboard/DELETE_TEST_STATUS";
export const UPDATE_TEST_SUBSCRIBERS = "cui/Dashboard/UPDATE_TEST_SUBSCRIBERS";
export const UPDATE_TEST_METADATA_LOADER =
    "cui/Dashboard/UPDATE_TEST_METADATA_LOADER";
export const UPDATE_TEST_NAME = "cui/Dashboard/UPDATE_TEST_NAME";
export const UPDATE_TEST_THRESHOLD = "cui/Dashboard/UPDATE_TEST_THRESHOLD";
export const UPDATE_TEST_EMAIL_TEMPLATES =
    "cui/Dashboard/UPDATE_TEST_EMAIL_TEMPLATES";
export const UPDATE_TEST_TIMEBOXED = "cui/Dashboard/UPDATE_TEST_TIMEBOXED";
export const UPDATE_TEST_ONELINK_MODE =
    "cui/Dashboard/UPDATE_TEST_ONELINK_MODE";
export const GENERATE_TEST_ONELINK = "cui/Dashboard/GENERATE_TEST_ONELINK";
export const GENERATE_TEST_ONELINK_SUCCESS =
    "cui/Dashboard/GENERATE_TEST_ONELINK_SUCCESS";
export const TOGGLE_TEST_ONELINK = "cui/Dashboard/TOGGLE_TEST_ONELINK";
export const UPDATE_TEST_PROCTORING_MODE =
    "cui/Dashboard/UPDATE_TEST_PROCTORING_MODE";
export const UPDATE_TEST_SUBSCRIBERS_LOADER =
    "cui/Dashboard/UPDATE_TEST_SUBSCRIBERS_LOADER";
export const COMPILE_CODE = "cui/Dashboard/COMPILE_CODE";
export const COMPILE_CODE_SUCCESS = "cui/shortlist/COMPILE_CODE_SUCCESS";
export const UPDATE_USER_ACTIVITY = "cui/Dashboard/UPDATE_USER_ACTIVITY";
export const REVIEW_INTERVIEW = "cui/Dashboard/REVIEW_INTERVIEW";
export const REMOVE_QUESTION_FROM_SCORECARD =
    "cui/Dashboard/REMOVE_QUESTION_FROM_SCORECARD";
export const GET_SELECT_REVIEWS = "cui/Dashboard/GET_SELECT_REVIEWS";
export const GET_SELECT_REVIEWS_SUCCESS =
    "cui/Dashboard/GET_SELECT_REVIEWS_SUCCESS";
export const REVIEW_INTERVIEW_SUCCESS =
    "cui/Dashboard/REVIEW_INTERVIEW_SUCCESS";
export const REVIEW_BULK_INTERVIEWS = "cui/Dashboard/REVIEW_BULK_INTERVIEWS";
export const REVIEW_BULK_INTERVIEWS_STATUS =
    "cui/Dashboard/REVIEW_BULK_INTERVIEWS_STATUS";

export const END_INTERVIEW = "cui/Dashboard/END_INTERVIEW";
export const END_INTERVIEW_SUCCESS = "cui/Dashboard/END_INTERVIEW_SUCCESS";
export const DELETE_INTERVIEW = "cui/Dashboard/DELETE_INTERVIEW";
export const DELETE_INTERVIEW_SUCCESS =
    "cui/Dashboard/DELETE_INTERVIEW_SUCCESS";
export const CANCEL_INTERVIEW = "cui/Dashboard/CANCEL_INTERVIEW";
export const CANCEL_INTERVIEW_SUCCESS =
    "cui/Dashboard/CANCEL_INTERVIEW_SUCCESS";
export const CANCEL_BULK_INTERVIEWS = "cui/Dashboard/CANCEL_BULK_INTERVIEWS";
export const CANCEL_BULK_INTERVIEWS_STATUS =
    "cui/Dashboard/CANCEL_BULK_INTERVIEWS_STATUS";
export const GET_TEST_LIBRARY = "cui/Dashboard/GET_TEST_LIBRARY";
export const GET_TEST_LIBRARY_SUCCESS =
    "cui/Dashboard/GET_TEST_LIBRARY_SUCCESS";
export const GET_ADDITIONAL_TESTS_DATA =
    "cui/Dashboard/GET_ADDITIONAL_TESTS_DATA";
export const GET_ADDITIONAL_TESTS_DATA_SUCCESS =
    "cui/Dashboard/GET_ADDITIONAL_TESTS_DATA_SUCCESS";
export const SEARCH_TEST_LIBRARY = "cui/Dashboard/SEARCH_TEST_LIBRARY";
export const SEARCH_TEST_LIBRARY_SUCCESS =
    "cui/Dashboard/SEARCH_TEST_LIBRARY_SUCCESS";
export const ERROR_AUDIT_LOGS = "cui/Dashboard/ERROR_AUDIT_LOGS";
export const ADD_NEW_TEST_REQUEST = "cui/Dashboard/ADD_NEW_TEST_REQUEST";
export const ADD_NEW_TEST_REQUEST_SUCCESS =
    "cui/Dashboard/ADD_NEW_TEST_REQUEST_SUCCESS";
export const ADD_UPDATE_EMAIL_TEMPLATE =
    "cui/Dashboard/ADD_UPDATE_EMAIL_TEMPLATE";
export const GET_INSIGHTS = "cui/Dashboard/GET_INSIGHTS";
export const GET_INSIGHTS_SUCCESS = "cui/Dashboard/GET_INSIGHTS_SUCCESS";
export const GET_CANDIDATE_FUNNEL = "cui/Dashboard/GET_CANDIDATE_FUNNEL";
export const GET_CANDIDATE_FUNNEL_SUCCESS =
    "cui/Dashboard/GET_CANDIDATE_FUNNEL_SUCCESS";
export const GET_SCORE_DISTRIBUTION = "cui/Dashboard/GET_SCORE_DISTRIBUTION";
export const GET_SCORE_DISTRIBUTION_SUCCESS =
    "cui/Dashboard/GET_SCORE_DISTRIBUTION_SUCCESS";
export const GET_DAYS_DATA = "cui/Dashboard/GET_DAYS_DATA";
export const GET_DAYS_DATA_SUCCESS = "cui/Dashboard/GET_DAYS_DATA_SUCCESS";
export const GET_INVITED_BY = "cui/Dashboard/GET_INVITED_BY";
export const GET_INVITED_BY_SUCCESS = "cui/Dashboard/GET_INVITED_BY_SUCCESS";
export const GET_AVG_COMPLETION_TIME = "cui/Dashboard/GET_AVG_COMPLETION_TIME";
export const GET_AVG_COMPLETION_TIME_SUCCESS =
    "cui/Dashboard/GET_AVG_COMPLETION_TIME_SUCCESS";
export const GET_AVG_RATINGS = "cui/Dashboard/GET_AVG_RATINGS";
export const GET_AVG_RATINGS_SUCCESS = "cui/Dashboard/GET_AVG_RATINGS_SUCCESS";
export const GET_REVIEWS = "cui/Dashboard/GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "cui/Dashboard/GET_REVIEWS_SUCCESS";

export const GET_SCORE_TIME_SERIES = "cui/Dashboard/GET_SCORE_TIME_SERIES";
export const GET_SCORE_TIME_SERIES_SUCCESS =
    "cui/Dashboard/GET_SCORE_TIME_SERIES_SUCCESS";

export const ADD_INTEGRATION_INFO = "cui/Dashboard/ADD_INTEGRATION_INFO";
export const ADD_SLACK_INFO = "cui/Dashboard/ADD_SLACK_INFO";
export const ADD_API_KEY = "cui/Dashboard/ADD_API_KEY";
export const TEST_SUCCESS_FACTORS = "cui/Dashboard/TEST_SUCCESS_FACTORS";
export const REMOVE_SAMPLE_CANDIDATES =
    "cui/Dashboard/REMOVE_SAMPLE_CANDIDATES";
export const ADD_MULTI_AUTH_DETAIL = "cui/Dashboard/ADD_MULTI_AUTH_DETAIL";
export const LOGOUT_USER = "cui/Dashboard/LOGOUT_USER";
export const GET_SUBSCRIPTION_INVOICES =
    "cui/Dashboard/GET_SUBSCRIPTION_INVOICES";
export const GET_SUBSCRIPTION_INVOICES_SUCCESS =
    "cui/Dashboard/GET_SUBSCRIPTION_INVOICES_SUCCESS";
export const GET_PAIRS_PAGINATED = "cui/Dashboard/GET_PAIRS_PAGINATED";
export const GET_PAIRS_PAGINATED_SUCCESS =
    "cui/Dashboard/GET_PAIRS_PAGINATED_SUCCESS";
export const CREATE_PAIR = "cui/Dashboard/CREATE_PAIR";
export const SEND_PAIR_INVITE_EMAIL = "cui/Dashboard/SEND_PAIR_INVITE_EMAIL";
export const SEND_BULK_INVITES = "cui/Dashboard/SEND_BULK_INVITES";
export const SEND_BULK_INVITES_SUCCESS =
    "cui/Dashboard/SEND_BULK_INVITES_SUCCESS";
export const REGISTER_CANDIDATE_PAIR_DETAILS =
    "cui/Dashboard/REGISTER_CANDIDATE_PAIR_DETAILS";
export const CREATE_READY_TEST = "cui/Dashboard/CREATE_READY_TEST";
export const GET_TEST_METADATA = "cui/Dashboard/GET_TEST_METADATA";
export const GET_TEST_METADATA_SUCCESS =
    "cui/Dashboard/GET_TEST_METADATA_SUCCESS";
export const GET_PUBLIC_QUESTIONS = "cui/Dashboard/GET_PUBLIC_QUESTIONS";
export const GET_PUBLIC_QUESTIONS_SUCCESS =
    "cui/Dashboard/GET_PUBLIC_QUESTIONS_SUCCESS";
export const GET_READY_ASSESSMENT_SUCCESS =
    "cui/Dashboard/GET_READY_ASSESSMENT_SUCCESS";
export const FETCH_ONBOARDING_DATA = "cui/Dashboard/FETCH_ONBOARDING_DATA";
export const FETCH_ONBOARDING_DATA_SUCCESS =
    "cui/Dashboard/FETCH_ONBOARDING_DATA_SUCCESS";
export const EXPORT_GRAPH_FETCH = "cui/Dashboard/EXPORT_GRAPH_FETCH";
export const EXPORT_GRAPH_FETCH_SUCCESS =
    "cui/Dashboard/EXPORT_GRAPH_FETCH_SUCCESS";
export const ADD_QUESTIONS_TO_TEST = "cui/Dashboard/ADD_QUESTIONS_TO_TEST";
export const ADD_QUESTIONS_TO_TEST_RESULT =
    "cui/Dashboard/ADD_QUESTIONS_TO_TEST_RESULT";
export const CUSTOMIZE_QUESTIONS_TO_TEST_RESULT =
    "cui/Dashboard/CUSTOMIZE_QUESTIONS_TO_TEST_RESULT";
export const SORT_QUESTIONS_DND = "cui/Dashboard/SORT_QUESTIONS_DND";
export const SORT_QUESTIONS_DND_RESULT =
    "cui/Dashboard/SORT_QUESTIONS_DND_RESULT";
export const DELETE_QUESTION_TEST = "cui/Dashboard/DELETE_QUESTION_TEST";
export const DELETE_PREVIEW_QUESTION_TEST =
    "cui/Dashboard/DELETE_PREVIEW_QUESTION_TEST";
export const VERIFY_QUESTION = "cui/Dashboard/VERIFY_QUESTION";
export const VERIFY_QUESTION_SUCCESS = "cui/Dashboard/VERIFY_QUESTION_SUCCESS";
export const DELETE_QUESTION_TEST_RESULT =
    "cui/Dashboard/DELETE_QUESTION_TEST_RESULT";
export const FETCH_RTG_DATA = "cui/Dashboard/FETCH_RTG_DATA";
export const FETCH_RTG_DATA_SUCCESS = "cui/Dashboard/FETCH_RTG_DATA_SUCCESS";
export const FETCH_SAAS_PLANS = "cui/Dashboard/FETCH_SAAS_PLANS";
export const FETCH_SAAS_PLANS_SUCCESS =
    "cui/Dashboard/FETCH_SAAS_PLANS_SUCCESS";

export const DELETE_CARD_DETAILS = "cui/Dashboard/DELETE_CARD_DETAILS";
export const DELETE_CARD_DETAILS_SUCCESS =
    "cui/Dashboard/DELETE_CARD_DETAILS_SUCCESS";

export const UPDATE_BILLING_DETAILS = "cui/Dashboard/UPDATE_BILLING_DETAILS";
export const UPDATE_BILLING_DETAILS_SUCCESS =
    "cui/Dashboard/UPDATE_BILLING_DETAILS_SUCCESS";
export const UPDATE_BILLING_DETAILS_ERROR =
    "cui/Dashboard/UPDATE_BILLING_DETAILS_ERROR";

export const CANCEL_SUBSCRIPTION = "cui/Dashboard/CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS =
    "cui/Dashboard/CANCEL_SUBSCRIPTION_SUCCESS";
export const GET_SHALLOW_INTERVIEWS = "cui/Dashboard/GET_SHALLOW_INTERVIEWS";
export const GET_SHALLOW_INTERVIEWS_SUCCESS =
    "cui/Dashboard/GET_SHALLOW_INTERVIEWS_SUCCESS";

export const GET_HIGH_LEVEL_INTERVIEWS_TOTAL =
    "cui/Dashboard/GET_HIGH_LEVEL_INTERVIEWS_TOTAL";
export const GET_HIGH_LEVEL_INTERVIEWS_TOTAL_SUCCESS =
    "cui/Dashboard/GET_HIGH_LEVEL_INTERVIEWS_TOTAL_SUCCESS";

export const GET_INTERVIEW_DETAILS = "cui/Dashboard/GET_INTERVIEW_DETAILS";
export const GET_INTERVIEW_DETAILS_SUCCESS =
    "cui/Dashboard/GET_INTERVIEW_DETAILS_SUCCESS";

export const PREVIEW_ASSESSMENT = "cui/Dashboard/PREVIEW_ASSESSMENT";
export const PREVIEW_ASSESSMENT_SUCCESS =
    "cui/Dashboard/PREVIEW_ASSESSMENT_SUCCESS";

export const GET_PREVIEW_TEST = "cui/Dashboard/GET_PREVIEW_TEST";
export const GET_PREVIEW_TEST_SUCCESS =
    "cui/Dashboard/GET_PREVIEW_TEST_SUCCESS";

export const FETCH_ADDRESS = "cui/Dashboard/FETCH_ADDRESS";
export const FETCH_ADDRESS_SUCCESS = "cui/Dashboard/FETCH_ADDRESS_SUCCESS";

export const FETCH_LIVE_UPDATES = "cui/Dashboard/FETCH_LIVE_UPDATES";
export const FETCH_LIVE_UPDATES_SUCCESS =
    "cui/Dashboard/FETCH_LIVE_UPDATES_SUCCESS";

export const FETCH_PROBLEMS = "cui/Dashboard/FETCH_PROBLEMS";
export const FETCH_PROBLEMS_SUCCESS = "cui/Dashboard/FETCH_PROBLEMS_SUCCESS";

export const SET_EDIT_PROBLEM = "cui/Dashboard/SET_EDIT_PROBLEM";

export const SAVE_PROBLEM = "cui/Dashboard/SAVE_PROBLEM";
export const SAVE_PROBLEM_SUCCESS = "cui/Dashboard/SAVE_PROBLEM_SUCCESS";
export const SAVE_PROBLEM_FAILURE = "cui/Dashboard/SAVE_PROBLEM_FAILURE";
export const CLEAR_SAVED_PROBLEM = "cui/Dashboard/CLEAR_SAVED_PROBLEM";

export const FETCH_SUBMISSIONS = "cui/Dashboard/FETCH_SUBMISSIONS";
export const FETCH_SUBMISSIONS_SUCCESS =
    "cui/Dashboard/FETCH_SUBMISSIONS_SUCCESS";

export const SET_EDIT_CUSTOM_QUESTION =
    "cui/Dashboard/SET_EDIT_CUSTOM_QUESTION";

export const SEND_CUSTOM_TEST_READY_EMAIL =
    "cui/Dashboard/SEND_CUSTOM_TEST_READY_EMAIL";

export const UPDATE_FAQS = "cui/Dashboard/UPDATE_FAQS";
export const UPDATE_FAQS_STATUS = "cui/Dashboard/UPDATE_FAQS_STATUS";

export const FETCH_NOTIFICATION_FEED =
    "cui/Dashboard/FETCH_NOTIFICATION_FEEDBACK";
export const FETCH_NOTIFICATION_FEED_STATUS =
    "cui/Dashboard/FETCH_NOTIFICATION_FEEDBACK_STATUS";

export const ADD_OR_UPDATE_LISTMONK = "cui/Dashboard/ADD_OR_UPDATE_LISTMONK";

export const GET_BLOCKED_EMAILS = "cui/Dashboard/GET_BLOCKED_EMAILS";
export const GET_BLOCKED_EMAILS_SUCCESS =
    "cui/Dashboard/GET_BLOCKED_EMAILS_SUCCESS";
export const DELETE_BLOCKED_EMAIL = "cui/Dashboard/DELETE_BLOCKED_EMAIL";
export const DELETE_BLOCKED_EMAIL_SUCCESS =
    "cui/Dashboard/DELETE_BLOCKED_EMAIL_SUCCESS";

export const EXTEND_INTERVIEW_DEADLINE =
    "cui/Dashboard/EXTEND_INTERVIEW_DEADLINE";
export const EXTEND_INTERVIEW_DEADLINE_SUCCESS =
    "cui/Dashboard/EXTEND_INTERVIEW_DEADLINE_SUCCESS";
export const EXTEND_INTERVIEW_DEADLINE_FAILURE =
    "cui/Dashboard/EXTEND_INTERVIEW_DEADLINE_FAILURE";

export const FETCH_ADA_AI_CONTACTS = "cui/Dashboard/FETCH_ADA_AI_CONTACTS";
export const FETCH_ADA_AI_CONTACTS_STATUS =
    "cui/Dashboard/FETCH_ADA_AI_CONTACTS_STATUS";
export const FETCH_ADA_AI_CONTACT_DOCUMENTS =
    "cui/Dashboard/FETCH_ADA_AI_CONTACTS_DOCUMENTS";
export const FETCH_ADA_AI_CONTACT_DOCUMENTS_STATUS =
    "cui/Dashboard/FETCH_ADA_AI_CONTACTS_DOCUMENTS_STATUS";
export const FETCH_ADA_AI_CONTACT_SESSIONS =
    "cui/Dashboard/FETCH_ADA_AI_CONTACTS_SESSION";
export const FETCH_ADA_AI_CONTACT_SESSIONS_STATUS =
    "cui/Dashboard/FETCH_ADA_AI_CONTACTS_SESSION_STATUS";
export const CREATE_ADA_AI_QUERY = "cui/Dashboard/CREATE_ADA_AI_QUERY";
export const CREATE_ADA_AI_QUERY_STATUS =
    "cui/Dashboard/CREATE_ADA_AI_QUERY_STATUS";
export const UPSERT_DOCUMENTS = "cui/Dashboard/UPSERT_DOCUMENTS";
export const UPSERT_DOCUMENTS_STATUS = "cui/Dashboard/UPSERT_DOCUMENTS_STATUS";
export const MODES = {
    SAVED_TESTS: "SAVED_TESTS",
    EDIT_API_KEYS: "EDIT_API_KEYS",
    EDIT_ROLES: "EDIT_ROLES",
    DASHBOARD: "DASHBOARD",
    DEFAULT: "DEFAULT",
    EDIT_MODES: "EDIT_MODES",
    PUSH_DATA: "PUSH_DATA",
    ADD_COMPANY: "ADD_COMPANY",
    SETTINGS: "SETTINGS",
    FAQS: "FAQS",
    TEST_BUILDER: "TEST_BUILDER",
    EDIT_USERS: "EDIT_USERS",
    SCORECARD: "SCORECARD",
    PRICING: "PRICING",
    SUBSCRIPTION: "SUBSCRIPTION",
    INVOICES: "INVOICES",
    PAYMENT_METHOD: "PAYMENT_METHOD",
    PAIR_PRICING: "PAIR_PRICING",
    PRE_SET_ASSESSMENTS: "PRE_SET_ASSESSMENTS",
    REQUEST_ASSESSMENT: "REQUEST_ASSESSMENT",
    TEST_LIBRARY: "TEST_LIBRARY",
    EMAIL_TEMPLATES: "EMAIL_TEMPLATES",
    INSIGHTS: "INSIGHTS",
    INTEGRATIONS: "INTEGRATIONS",
    LOGS: "LOGS",
    PAIR: "PAIR",
    CANDIDATES: "CANDIDATES",
    INTERVIEWID: "INTERVIEWID",
    CANDIDATES_DISCOVERY: "CANDIDATES_DISCOVERY",
    IMAGE_UPLOADER: "IMAGE_UPLOADER",
    TEST_SETTINGS: "TEST_SETTINGS",
    LIVE_UPDATES: "LIVE_UPDATES",
    JUDGE0_PROBLEMS: "JUDGE0_PROBLEMS",
    JUDGE0_PROBLEMCREATE: "JUDGE0_PROBLEMCREATE",
    JUDGE0_PROBLEMEDIT: "JUDGE0_PROBLEMEDIT",
    JUDGE0_SUBMISSIONS: "JUDGE0_SUBMISSIONS",
    RESUME: "RESUME",
    RESUME_CONTACT: "RESUME_CONTACT",
    BLOCKED_EMAILS: "BLOCKED_EMAILS",
};

export const ADMIN_MODES = [
    "EDIT_API_KEYS",
    "EDIT_MODES",
    "EDIT_ROLES",
    "ADD_COMPANY",
    "PUSH_DATA",
    "ADD_COMPANY",
    "SETTINGS",
    "EDIT_USERS",
    "TEST_BUILDER",
    "EMAIL_TEMPLATES",
    "INSIGHTS",
    "LOGS",
    "INTEGRATIONS",
    "PAIR",
    "IMAGE_UPLOADER",
    "RESUME",
    "RESUME_CONTACT",
];

export const SUB_NAVBAR = [
    {
        show: true,
        display: "Subscription",
        key: "SUBSCRIPTION",
        param: "/pricing/subscription",
    },
    {
        show: true,
        display: "Invoices",
        key: "INVOICES",
        param: "/pricing/invoices",
    },
    {
        show: true,
        display: "Payment method",
        key: "PAYMENT_METHOD",
        param: "/pricing/payment-method",
    },
];
